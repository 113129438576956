import React from 'react'
import Banner from '../Banner/Banner'
const Home = () => {
  return (
    <div>
        <Banner></Banner>
    </div>
  )
}

export default Home