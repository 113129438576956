import React from 'react'
import {Link} from 'react-router-dom';

const Banner = () => {
  return (
    <div>
        <section id="hero" class="hero d-flex align-items-center">
            <div class="container">
                <div class="row gy-4 d-flex justify-content-between">
                    <div class="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                    <h2 data-aos="fade-up">We Have To Build Your Dream</h2>
                    <p data-aos="fade-up" data-aos-delay="100">Facere distinctio molestiae nisi fugit tenetur repellat non praesentium nesciunt optio quis sit odio nemo quisquam. eius quos reiciendis eum vel eum voluptatem eum maiores eaque id optio ullam occaecati odio est possimus vel reprehenderit</p>

                    <a class="get-a-quote" href="get-a-quote.html">Apply Now</a>
                    </div>

                    <div class="col-lg-5 order-1 order-lg-2 hero-img" data-aos="zoom-out">
                    <img src="https://f.hubspotusercontent10.net/hubfs/19956213/academia/banner-one.png" class="img-fluid mb-3 mb-lg-0" alt="" />
                    </div>

                </div>
            </div>
        </section>
        <section id="featured-services" class="featured-services">
            <div class="container">
                <div class="row gy-4 d-flex">
                    <div class="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up">
                        <div class="icon flex-shrink-0"><img src="https://img.freepik.com/free-vector/passport-with-flight-tickets-white-background_1308-90416.jpg?t=st=1716072016~exp=1716075616~hmac=c9f1d1dc632b4b58ff0115686b0cccdd0f282d68f7c717e5c022031763e92a17&w=826" alt="" /></div>
                        <div>
                        <h4 class="title">Visa</h4>
                        <p class="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                        <a href="service-details.html" class="readmore stretched-link"><span>Learn More</span><i class="bi bi-arrow-right"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay="100">
                        <div class="icon flex-shrink-0"><img src="https://img.freepik.com/free-vector/indian-rupee-money-bag_23-2147991485.jpg?t=st=1716072412~exp=1716076012~hmac=c99f7e6508c9d3ad82638e8e1696da0077ebda63838fc1fd3517547a3b8ded71&w=826" alt="" /></div>
                        <div>
                        <h4 class="title">Loans</h4>
                        <p class="description">Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p>
                            <Link to="/services/apply-loan"><span>Learn More</span><i class="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Banner