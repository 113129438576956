import React from 'react'

const HomeLoan = () => {
  return (
    <section id="inner_loan_section">
      <div class="services-details-desc">
        <div class="image">
          <img src="/vinix/img/services-details.2467e6fd.jpg" alt="image" /></div>
            <div class="content">
              <h3>About business loan and how does it work</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
              <p class="text">Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. At vero eos et accusam et justo duo dolores et ea rebum.</p>
            </div>
            <div class="services-details-features">
              <div class="content">
                  <h3>Features of business loan</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
              </div>
              <div class="row">
                  <div class="col-lg-4 col-md-6">
                    <div class="features-item">
                        <div class="icon"><img src="/assets/img/icons/personal-loan.png" alt="" /></div>
                        <h3>Quick <br /> approval</h3>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                    <div class="features-item">
                        <div class="icon"><img src="/assets/img/icons/personal-loan.png" alt="" /></div>
                        <h3>Easy loan <br/> Repayment</h3>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                    <div class="features-item">
                        <div class="icon"><img src="/assets/img/icons/personal-loan.png" alt="" /></div>
                        <h3>100% <br/> Transparency</h3>
                    </div>
                  </div>
              </div>
            </div>
            <div class="services-details-others">
              <h3>You deserve a better business loan</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
            </div>
            <div class="deserve-item">
              <h3>We usually follow 4 steps to get a better business loans.</h3>
              <div class="deserve-content">
                  <span>1</span>
                  <h4>Apply in 10 minutes</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
              </div>
              <div class="deserve-content">
                  <span>2</span>
                  <h4>Hear from us in 1 hour</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
              </div>
              <div class="deserve-content">
                  <span>3</span>
                  <h4>A decision in 24 hours</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
              </div>
              <div class="deserve-content">
                  <span>4</span>
                  <h4>Your loan is funded</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
              </div>
              <div class="deserve-btn"><a href="/vinix/apply-now" class="default-btn"> Apply now <span></span></a></div>
            </div>
            <div class="services-details-list">
              <div class="content">
                  <h3>Eligibility Criteria</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                  <ul class="list">
                    <li><i class="fa fa-check" aria-hidden="true"></i> You must be a Salaried Employee </li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> Age: 20-50 years old </li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> Net Monthly Income must be more than $5000. </li>
                    <li><i class="fa fa-check" aria-hidden="true"></i> No defaults in the last 24 months. </li>
                  </ul>
              </div>
            </div>
        </div>

    </section>
  )
}

export default HomeLoan