import React from 'react';
import {Routes, Route ,useLocation, Link} from 'react-router-dom';

const Header = () => {
  return (
    <div>
        <header id="header" class="header d-flex align-items-center fixed-top">
            <div class="container-fluid container-xl d-flex align-items-center justify-content-between">

            <a href="index.html" class="logo d-flex align-items-center">
                 {/* Uncomment the line below if you also wish to use an image logo  */}
                <img src="/assets/img/logo.png" className="App-logo" alt="logo" />
                <h1>swastik overseas</h1>
            </a>

            <i class="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
            <i class="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
            <nav id="navbar" className="navbar">
                <ul>
                    <li>
                    <Link to="/" className="active">Home</Link>
                    </li>
                    <li>
                    <Link to="/about">About</Link>
                    </li>
                    <li className="dropdown">
                    <a href="#"><span>Services</span> <i className="bi bi-chevron-down dropdown-indicator"></i></a>
                    <ul>
                        <li><Link to="/services/apply-loan">Apply Loan</Link></li>
                        <li><Link to="/apply-visa">Apply Visa</Link></li>
                    </ul>
                    </li>
                    <li>
                    <Link to="/pricing">Pricing</Link>
                    </li>
                    <li className="dropdown">
                    <a href="#"><span>Drop Down</span> <i className="bi bi-chevron-down dropdown-indicator"></i></a>
                    <ul>
                        <li><Link to="/drop-down-1">Drop Down 1</Link></li>
                        <li className="dropdown">
                        <a href="#"><span>Deep Drop Down</span> <i className="bi bi-chevron-down dropdown-indicator"></i></a>
                        <ul>
                            <li><Link to="/deep-drop-down-1">Deep Drop Down 1</Link></li>
                            <li><Link to="/deep-drop-down-2">Deep Drop Down 2</Link></li>
                            <li><Link to="/deep-drop-down-3">Deep Drop Down 3</Link></li>
                            <li><Link to="/deep-drop-down-4">Deep Drop Down 4</Link></li>
                            <li><Link to="/deep-drop-down-5">Deep Drop Down 5</Link></li>
                        </ul>
                        </li>
                        <li><Link to="/drop-down-2">Drop Down 2</Link></li>
                        <li><Link to="/drop-down-3">Drop Down 3</Link></li>
                        <li><Link to="/drop-down-4">Drop Down 4</Link></li>
                    </ul>
                    </li>
                    <li>
                    <Link to="/contact">Contact</Link>
                    </li>
                    <li>
                    <Link className="get-a-quote" to="/get-a-quote">Apply Now</Link>
                    </li>
                </ul>
            </nav>

            </div>
        </header>
    </div>
  )
}

export default Header