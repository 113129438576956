import React from 'react'
import { Link, Outlet} from 'react-router-dom';

const Category = () => {
  return (
    <div className='container mt-5'>
    <div className="spacer-200"></div>
    <div class="row gy-4 d-flex">
        <div className="col-lg-4 col-md-12  order-lg-0 order-2 mt-3 mt-md-0 mt-lg-0 widget-area">
            <section class="widget widget_categories">
                <h3 class="widget-title">Categories</h3>
                <ul>
                    <li><Link className='country-links' id="canada" to="/category/home-loan">Business Loan</Link></li>
                    <li><Link className='country-links' id="germany" to="/category/home-loan">Home Loan</Link></li>
                    <li><Link className='country-links' id="australia" to="/category/home-loan">Personal Loan</Link></li>
                    <li><Link className='country-links' id="united-state" to="/category/home-loan">Education loan</Link></li>
                    <li><Link className='country-links' id="new-zealand" to="/category/home-loan">Vehicle Loan</Link></li>
                    <li><Link className='country-links' id="united-kingdom" to="/category/home-loan">Medical Loan</Link></li>                                                
                    </ul>
            </section>
            <section class="widget widget_hours">
                <h3 class="widget-title">Opening Hours</h3>
                <ul>
                    <li> Mon – Thurs <span>8:00 AM - 5:00 PM</span></li>
                    <li> Fri - Satur <span>8:00 AM - 3:00 PM</span></li>
                    <li> Sun <span>CLOSED</span></li>
                </ul>
                </section>
            {/* <BlogForm country={curret_active}></BlogForm> */}
        </div>
        <div className="col-lg-8 col-md-12 order-lg-0 order-2 mt-3 mt-md-0 mt-lg-0">
            <Outlet></Outlet>
        </div>
    </div>
    </div>
  )
}

export default Category