import './App.css';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import HomeLoan from './components/Service/Loan/Routes/HomeLoan/HomeLoan';
import Loan from './components/Service/Loan/Loan';
import Service from './components/Service/Service';
import {Routes, Route ,useLocation, Link} from 'react-router-dom';
import Category from './components/Service/Loan/Routes/Category';

function App() {
  return (
    <div className="App">
      <Header></Header>
      <Routes>
          <Route exact path="/" element={<Home />}></Route> 
          {/* <Route exact path="/Contact-us" element={<ContactUs isFromRoute={true} />}></Route> 
          <Route exact path="/ielts-exam-dates" element={<ExamCalender />}></Route>  */}
          {/* <Route path="/services" element={<Service />}> */}
          <Route path="/services/apply-loan" element={<Loan />}></Route>
          <Route path="/category" element={<Category />}>
            <Route path="home-loan" element={<HomeLoan />}></Route>
          </Route>           
           
          {/* <Route path="/apply-loan/home-loan" element={<HomeLoan />}></Route> */}
          
        </Routes>
    </div>
  );
}

export default App;
