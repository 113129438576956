import React from 'react'
import {Routes, Route ,useLocation, Link} from 'react-router-dom';

const Loan = () => {
  return (
    <section id="service" class="services pt-0">
      <div class="container" data-aos="fade-up">

        <div class="row">
            <div class="col-lg-6 order-1 order-lg-1 d-flex flex-column justify-content-start loan-section-heading">
                <h1 data-aos="fade-up">Explore the Range of Loantema's Financial Services</h1>
            </div>
            <div className="col-1 order-2"></div>
            <div class="col-lg-5 order-3 order-lg-2 d-flex flex-column justify-content-end" data-aos="zoom-out">
                <h6 className="text-muted" data-aos="fade-up">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi officiis obcaecati consequatur soluta distinctio fugiat sequi ullam consequuntur, rem quasi fuga nihil nobis numquam.</h6>
                <a href="" className='see-all-service-btn'>See All Service</a>
            </div>
        </div>
        <div className="spacer-100"></div>
        <div class="row gy-4">
            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <div class="icon">
                    <img src="/assets/img/icons/business-loan.png" alt="" />
                    </div>
                <h3>Business loan</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                <Link to="/category/home-loan" class="learn-btn">Learn More</Link>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <div class="icon">
                    <img src="/assets/img/icons/home-loan.png" alt="" />
                    </div>
                <h3>House loan</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                <Link to="/category/home-loan" class="learn-btn">Learn More</Link>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <div class="icon">
                        <img src="/assets/img/icons/personal-loan.png" alt="" />
                    </div>
                <h3>Personal loan</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                <Link to="/category/home-loan" class="learn-btn">Learn More</Link>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <div class="icon">
                    <img src="/assets/img/icons/education-loan.png" alt="" />
                    </div>
                <h3>Education loan</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                <Link to="/category/home-loan" class="learn-btn">Learn More</Link>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <div class="icon">
                    <img src="/assets/img/icons/vehicle-loan.png" alt="" />
                    </div>
                <h3>Vehicle loan</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                <Link to="/category/home-loan" class="learn-btn">Learn More</Link>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <div class="icon">
                    <img src="/assets/img/icons/medical-loan.png" alt="" />
                    </div>
                <h3>Medical loan</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                <Link to="/category/home-loan" class="learn-btn">Learn More</Link>
                </div>
            </div>
          

        </div>

      </div>
    </section>
  )
}

export default Loan